const keywordsGenerator = async (text,selectedModel) => {
  let data;

  const ipaddress = "100.26.32.20";
  //const ipaddress = "localhost";

    try {

      const message1 = { "role": `system`, "content": `Voglio che tu risponda solo in lingua italiana fingendo di essere un esperto di ricerca di parole chiave SEO. Sulla base di [TESTO] estrai le 5 possibili chiavi lunghe (long keyword) dando priorità a quelle che usano avverbi interrogativi (dove, come, quando). Non usare per le long keyword in elenchi puntati o altri elenchi. Rispondi con una stringa di testo usando la virgola come separatore delle keyword.` };
      const message2 = { "role": `user`, "content": `[TESTO]: ${text}\nFine del testo. Non fare eco alla mia richiesta.` };

      //const response = await fetch('http://localhost:3001/api/gpt4', {
      const response = await fetch(`http://${ipaddress}:7070/api/${selectedModel}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message: [message1, message2] })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      data = await response.json();
      console.log(data);

    } catch (error) {
      console.error(error);
    }

  return data;
};

export default keywordsGenerator;