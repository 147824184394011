import React, { useState } from 'react';
import axios from 'axios';
import { Container, Grid, TextField, Button, List, ListItem, ListItemText, Divider, Select, MenuItem, Link, Typography } from '@mui/material';

const ipaddress = "100.26.32.20";
//const ipaddress = "localhost";

const SearchOnBinG = ({ onStart, onFinishSelecting}) => {
  const [searchKey, setSearchKey] = useState("");
  const [searchEngine, setSearchEngine] = useState("googlenews");
  const [searchResults, setSearchResults] = useState([]);
  const [currentSelectedNews, setCurrentSelectedNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearch = async () => {
    try {
      const response = await axios.get(`http://${ipaddress}:7070/api/search/${searchEngine}?q=${encodeURIComponent(searchKey)}&p=0`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Errore nella ricerca:', error);
    }
  };

const handleNewsSelection = (index, link) => {
  const isSelected = currentSelectedNews.some(news => news.link === link);
  if (!isSelected) {
    const newsItem = {
      index,
      link,
      title: searchResults[index].title,
      snippet: searchResults[index].snippet
    };
    setCurrentSelectedNews([...currentSelectedNews, newsItem]);
  }
};

const handleNewsRemoval = (link) => {
  const updatedSelectedNews = currentSelectedNews.filter(news => news.link !== link);
  setCurrentSelectedNews(updatedSelectedNews);
};

const handleFinishSelecting = () => {
  onFinishSelecting(currentSelectedNews);
};


  const loadMoreResults = async () => {
    try {
      const nextPage = currentPage + 1;
      const response = await axios.get(`http://${ipaddress}:7070/api/search/${searchEngine}?q=${encodeURIComponent(searchKey)}&p=${nextPage}`);
      setSearchResults([...searchResults, ...response.data]);
      setCurrentPage(nextPage);
    } catch (error) {
      console.error('Errore nel caricamento di più risultati:', error);
    }
  };

  return (
    <div style={{ maxWidth: 900, width: '100%' }}>
    <Container maxWidth="lg">
          <Typography variant="h2"><span role="img" aria-label="Robot">🤖</span> ChatGPMik</Typography>
      <h1 style={{ display: 'flex', alignItems: 'center' }}>Cerca un argomento</h1>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Select
          value={searchEngine}
          onChange={(e) => setSearchEngine(e.target.value)}
          style={{ marginRight: '10px' }}
        >
          <MenuItem value="googlenews">Google News</MenuItem>
          <MenuItem value="bing">Bing</MenuItem>
          <MenuItem value="google">Google</MenuItem>
        </Select>
        <TextField
          variant="outlined"
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder="Cerca..."
          style={{ flex: 1, marginRight: '10px' }}
        />
        <Button variant="contained" onClick={handleSearch}>Cerca</Button>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <List>
            {searchResults.map((result, index) => (
              <ListItem
                key={index}
                onClick={() => handleNewsSelection(index, result.link)}
                button
              >
                <ListItemText
                  primary={
                    <Link href={result.link} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                      {result.title}
                    </Link>
                  }
                  secondary={`${result.site} - ${result.snippet}`}
                />
              </ListItem>
            ))}
          </List>
          
            {searchResults.length > 0 && (
            <Button variant="contained" onClick={loadMoreResults} style={{ marginBottom:'20px' }}>Carica altri risultati</Button>
            )}
          
        </Grid>
        <Grid item xs={6}>
          <div>
{currentSelectedNews.map((news, index) => (
  <div key={index}>
    <ListItem>
      <ListItemText primary={news.title} secondary={news.snippet} />
      <Button onClick={() => handleNewsRemoval(news.link)}>Rimuovi</Button>
    </ListItem>
    <Divider />
  </div>
))}
                  <Button variant="contained" onClick={handleFinishSelecting} style={{ marginTop:'20px' }}>Ho finito (oppure vai al Tool)</Button>

          </div>
        </Grid>
      </Grid>
    </Container>
    </div>
  );
};

export default SearchOnBinG;
