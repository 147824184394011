// App.js
import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Box, CircularProgress } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import keywordsGenerator from './KeywordsGenerator'; // Importa la funzione keywordsGenerator dal file esterno
import SummarizeContent from './SummarizeContent'; // Importa la funzione SummarizeContent dal file esterno
import SearchOnBinG from './SearchOnBinG';
import prompts from './prompts.json'; // Importa il file JSON

//per deployare devo:
//1. cambiare ipaddress
//2. cambiare indirizzo per express su porta 2001

const ipaddress = "100.26.32.20";
//const ipaddress = "localhost";

//const infoaggiuntive = "[ISTRUZIONI AGGIUNTIVE]:\nScrivi l'articolo in forma \"noi\". Nel tuo testo non menzionare nessuna azienda in nome della quale il testo è scritto. Per i nomi propri non inserire l'articolo determinativo davanti. Rispondi in formato json secondo la struttura come segue: {\"title\": \"\",\"title_seo\": \"\",\"permalink_seo\": \"\",\"meta_description\": \"\",\"keywords\": \"\",\"content\": \"\"}\n- Scrivi il Titolo. No html, massimo 80 caratteri, non fare eco alla mia richiesta.\n- Scrivi il Titolo seo. No html, massimo 55 caratteri, non fare eco alla mia richiesta.\n- Scrivi il Permalink seo.\n- Scrivi la Meta Description, non clickbait ma che spinga ad approfondire leggendo l'articolo.\n- Scrivi 2 Keywords più importanti, separale da una virgola.\n- Scrivi il Contenuto in formato html (non scrivere l'h1, non iniziare con h2 o altri titoli, non usare div)";

const infoaggiuntive = "Non utilizzare mai la prima persona singolare.\nPer i nomi propri non inserire l'articolo determinativo davanti.\nRispondi in formato json secondo la struttura come segue, se usi doppie virgolette metti un escape: {\"title\": \"\",\"title_seo\": \"\",\"permalink_seo\": \"\",\"meta_description\": \"\",\"keywords\": \"\",\"content\": \"\"}\n- Scrivi il Titolo. No html, massimo 80 caratteri.\n- Scrivi il Titolo seo. No html, massimo 55 caratteri.\n- Scrivi il Permalink seo.\n- Scrivi la Meta Description, non clickbait ma che spinga ad approfondire leggendo l'articolo.\n- Scrivi 2 Keywords più importanti, separale da una virgola.\n- Scrivi il Contenuto in formato html: non scrivere l'h1, non iniziare con h2 o altri titoli e non usare div\n- Riporta tutto il testo che trovi racchiuso tra {{ }} assicurandoti di  attribuite correttamente le citazioni nel tuo testo  all'interno di un tag <blockquote>.\nNon fare eco alle mie richieste.";
/*const prompts_finali = [
    {
        name: "titolo",
        role1: "In riferimento alla richiesta precedente",
        role2: "Genera il titolo. No html, massimo 80 caratteri, non fare eco alla mia richiesta."
    },
    {
        name: "titolo_seo",
        role1: "In riferimento alla richiesta precedente",
        role2: "Genera il titolo seo. No html, massimo 55 caratteri, non fare eco alla mia richiesta."
    },
    {
        name: "permalink",
        role1: "In riferimento alla richiesta precedente",
        role2: "Genera il permalink seo."
    },
    {
        name: "meta_description",
        role1: "In riferimento alla richiesta precedente",
        role2: "Genera la meta description, non clickbait ma che spinga ad approfondire leggendo l'articolo."
    },
    {
        name: "keywords",
        role1: "In riferimento alla richiesta precedente",
        role2: "Genera 2 keywords più importanti, separale da una virgola."
    }
];*/

function App() {

  const [website, setWebsite] = useState('tutto.tv');
  const [postId, setPostId] = useState('');
  const [selectedModel, setModel] = useState('gpt-4');
  const [showSearchOnBinG, setShowSearchOnBinG] = useState(true);

  const [role, setRole] = useState('system');
  const [role2, setRole2] = useState('user');
  const [textFieldValue, setTextFieldValue] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textlinks, setTextLinks] = useState('');
  const [avvisi, setAvvisi] = useState('');

  const [editedTitle, setEditedTitle] = useState('');
  const [editedTitleSeo, setEditedTitleSeo] = useState('');
  const [editedPermalink, setEditedPermalink] = useState('');
  const [editedMetaDescription, setEditedMetaDescription] = useState('');
  const [editedKeywords, setEditedKeywords] = useState('');
  const [editedContent, setEditedContent] = useState('');

  const [showTextFields1, setShowTextFields1] = useState(false);
  const [showTextFields2, setShowTextFields2] = useState(false);
  const [showTextFields3, setShowTextFields3] = useState(false);

  let [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const avvisiRef = useRef(null);

 // Aggiungi uno stato per il numero totale di token utilizzati
  const [totalTokens, setTotalTokens] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const tokenprice = 0.045; //media tra 0,03 prompt e 0,06 sampled
  const tokenprice35 = 0.00175; //media tra 0,03 prompt e 0,06 sampled

  const [selectedPrompt, setSelectedPrompt] = useState(prompts[0]); // Stato per il prompt selezionato
  const [linkArray, setLinkArray] = useState([]); // Stato per l'array con indice link, link, testo

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleRole2Change = (event) => {
    setRole2(event.target.value);
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const handleTextField2Change = (event) => {
    setTextFieldValue2(event.target.value);
  };

  const handleTextLinks2Change = (event) => {
    setTextLinks(event.target.value);
  };

  const handleWebsite2Change = (event) => {
    setWebsite(event.target.value);
  };

  const changeResponse = (event) => {
    setResponse(event.target.value);
  }

  const changeAvvisi = (event) => {
    setAvvisi(event.target.value);
  }

  const handleModelChange = (event) => {
      setModel(event.target.value);
  };

  const handleFinishSelecting = (selectedNews) => {

    const linksText = selectedNews.map((news) => news.link).join('\n');
    setTextLinks(linksText);
    setShowSearchOnBinG(false);
  };

  const handleElaboraText = async (event) => {
    setIsLoading(true);

    setAvvisi(avvisi + `\nElaboro il testo, se ci sono link li converto in testo`);
      //se sono presenti dei link da convertire, procedo
      if (textlinks.length >3) {
        setTextLinks( await processText(textlinks));
      }

    setIsLoading(false);
    setShowTextFields1(true); 
  }

    const handlePromptChange = async (event) => {
      setIsLoading(true);
      // Imposta i valori di textfield1 e textfield2 con i testi del prompt selezionato
      const selectedPromptIndex = event.target.value;

      setSelectedPrompt(prompts[selectedPromptIndex]);
      setTextFieldValue(prompts[selectedPromptIndex].role1);

      let textWithSchemaArticles = prompts[selectedPromptIndex].role2;

      setAvvisi(avvisi + `\nSostituisco al prompt [REPLACECONTENUTI] col testo elaborato`);

      if (textWithSchemaArticles.includes("[REPLACECONTENUTI]") && textlinks.length >3) {
      // Usa await per aspettare la risoluzione dell'array di link
        textWithSchemaArticles = textWithSchemaArticles.replace("[REPLACECONTENUTI]", "[CONTENUTI]:\n"+textlinks+"\n[FINE CONTENUTI]");
      }

      //se è presente GENERAKEYWORDS invoco la relativa funzione e sostituzione
      if (textWithSchemaArticles.includes("[GENERAKEYWORDS]")  && textlinks.length > 50) {

        const result = await processTextKeywords(textWithSchemaArticles);

        if(result){
          textWithSchemaArticles = textWithSchemaArticles.replace("[GENERAKEYWORDS]", result);
          setAvvisi(avvisi+`\nCaricate le keywords contenuto `);
        }else{
          setAvvisi(avvisi+`\nErrore generazione keywords`);
        }

      }else{
        setAvvisi(avvisi+`\nNon serve che carico le keywords (o il testo è troppo corto)`);
      }

        setTextFieldValue2(textWithSchemaArticles+"\n"+infoaggiuntive);

      setIsLoading(false);

    };

// Funzione ausiliaria per sostituire i link con le risposte ottenute
const replaceLinks = async (text) => {
  const linkRegex = /(https?:\/\/[^\s]+)/g;
  const matches = text.match(linkRegex);

  if (!matches || matches.length === 0) {
    return text;
  }

  const responses = await Promise.all(
    matches.map(async (link) => {
      //const response = await fetch(`http://localhost:8888/testcomposer/test.php?url=${encodeURIComponent(link)}`);
      const response = await fetch(`http://${ipaddress}/readability/test.php?url=${encodeURIComponent(link)}`);
      const data = await response.json();
      return data.response.replace(/(<\/?(?:h1|h2|h3|h4|h5|b|strong)[^>]*>)|<[^>]+>|(\r\n|\n\n)/ig, '$1');
    })
  );

  let replacedText = text;
  matches.forEach((link, index) => {
    replacedText = replacedText.replace(link, `[${responses[index]}]`);
  });

  return replacedText;
};

async function summarizeLinkTextFieldChange() {
   setIsLoading(true);
  setLinkArray([]);
  const links = textlinks.split(/\s+/); // Dividiamo il campo di testo usando gli spazi come separatore
  const responses = await Promise.all(
    links.map(async (link) => {
      const textWithReplacedLink = await replaceLinks(link);
      const summary = await SummarizeContent(textWithReplacedLink);

            const numTokens = summary.response.usage.total_tokens || 0;
            setTotalTokens((prevTotalTokens) => prevTotalTokens + numTokens);
            // Calcola e imposta il costo totale delle richieste in tokens
            const costInTokens = numTokens / 1000 * tokenprice35;
            setTotalCost((prevTotalCost) => prevTotalCost + costInTokens);

      return { link: link, text: summary.response.choices[0].message.content };
    })
  );
  setLinkArray(responses);
   setIsLoading(false);
  return responses; // Restituisci direttamente l'array di link generati e risolti
}

const processText = async (text) => {
    let processedText = text;

    const linkRegex = /(http[s]?:\/\/[^\s]+)/g;
    const linksFound = text.match(linkRegex);

    if (linksFound) {
        for (const link of linksFound) {
            const replacedText = await replaceLinks2(link);
            processedText = processedText.replace(link, `${replacedText}\n`);
        }
        console.log(processedText);
        return processedText;
    } else {
        return text;
    }
};

const replaceLinks2 = async (link) => {
    const response = await fetch(`http://${ipaddress}/readability/test.php?url=${encodeURIComponent(link)}`);
    const data = await response.json();
    return data.response.replace(/(<\/?(?:h1|h2|h3|h4|h5|b|strong)[^>]*>)|<[^>]+>|(\r\n|\n\n)/ig, '$1');
};

async function processTextKeywords(text) {
    setIsLoading(true);
      const keywordsGenerated = await keywordsGenerator(text,selectedModel);

      const numTokens = keywordsGenerated.response.usage.total_tokens || 0;
      setTotalTokens((prevTotalTokens) => prevTotalTokens + numTokens);
      
      // Calcola e imposta il costo totale delle richieste in tokens
      const costInTokens = numTokens / 1000 * tokenprice;
      setTotalCost((prevTotalCost) => prevTotalCost + costInTokens);

    setIsLoading(false);
    return keywordsGenerated.response.choices[0].message.content;
}

  const handlePromptSubmit = async () => {
        setIsLoading(true);

    try {

      setAvvisi(avvisi + `\nAvvio richiesta di generazione a GPT4 `);

      const textWithReplacedLinks1 = textFieldValue;
      const textWithReplacedLinks2 = textFieldValue2;

      //da cancellare?
      let textWithSchemaArticles = textWithReplacedLinks2;
      if (textWithReplacedLinks2.includes("[CONTENUTI]")) {
        linkArray.forEach((linkInfo) => {
          const schemaArticle = `[SCHEMA ARTICOLO ${linkInfo.index}]\n${linkInfo.text}\n\n`;
          textWithSchemaArticles = textWithSchemaArticles.replace("[CONTENUTI]", schemaArticle);
        });
      }

      const message1 = { "role": role, "content": textWithReplacedLinks1 };
      const message2 = { "role": role2, "content": textWithSchemaArticles };

      console.log(message1);
      console.log(message2);

      //const response = await fetch('http://54.175.3.49:2001/api/gpt4', {
      const response = await fetch(`http://${ipaddress}:7070/api/${selectedModel}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message: [message1, message2] })
      });

      if (!response.ok) {
        setAvvisi(avvisi + `\nErrore richiesta a ${selectedModel}: ${response.status}`);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);

      if(data.response.choices[0].message.content.length > 1){
        setAvvisi( avvisi + `\nArticolo generato con ${selectedModel}! :)`);
      }

      setResponse(data.response.choices[0].message.content);


      const numTokens = data.response.usage.total_tokens || 0;
      setTotalTokens((prevTotalTokens) => prevTotalTokens + numTokens);

      // Calcola e imposta il costo totale delle richieste in tokens
      const costInTokens = numTokens / 1000 * tokenprice;
      setTotalCost((prevTotalCost) => prevTotalCost + costInTokens);

    } catch (error) {
      console.error(error);
    }

        setIsLoading(false);
        setShowTextFields2(true); 

  };

const handleOtherSubmit = async () => {

  let responseSplit;

    try {
      responseSplit = JSON.parse(response.replace(/[\n\r]/g, ''));
      // Utilizza la variabile responseSplit per il tuo codice

      setEditedTitle(responseSplit.title);
      setEditedTitleSeo(responseSplit.title_seo);
      setEditedPermalink(responseSplit.permalink_seo);
      setEditedMetaDescription(responseSplit.meta_description);
      setEditedKeywords(responseSplit.keywords);
      setEditedContent(responseSplit.content);

      setAvvisi(avvisi+`\nRisposta suddivisa`);

    } catch (error) {
      setAvvisi(avvisi + '\nLa risposta è in formato JSON errato, non riesco a suddividerla nei vari elementi.');
    }

    setShowTextFields3(true); 

};


function handleUploadToWebsite() {

  setAvvisi(avvisi+`\nInvio caricamento su Wordpress`);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      title: editedTitle.replace(/[\n\r]/g, ''),
      title_seo: editedTitleSeo.replace(/[\n\r]/g, ''),
      permalink_seo: editedPermalink.replace(/[\n\r]/g, ''),
      meta_description: editedMetaDescription.replace(/[\n\r]/g, ''),
      keywords: editedKeywords.replace(/[\n\r]/g, ''),
      content: editedContent.replace(/[\n\r]/g, ''),
      website: website
    }),
  };

  fetch(`http://${ipaddress}:7070/api/upload`, requestOptions)
    .then(response => response.json())
    .then(data => {
      setPostId(postId);
      console.log('Post ID:', data.postId);
        setAvvisi(avvisi+`\nCaricamento riuscito, postID: ${data.postId}`);
    })
    .catch(error => {
      console.error('Error:', error);
        setAvvisi(avvisi+`\nCaricamento fallito: ${error}`);
    });
}

// Aggiungi questo componente sotto il codice esistente nell'interfaccia utente
const PostLink = ({ website, postId }) => {
  const postEditLink = `https://www.${website}/wp-admin/post.php?post=${postId}&action=edit`;

  return (
    <Typography variant="body1">
      <a href={postEditLink} target="_blank" rel="noopener noreferrer">
        Modifica l'articolo
      </a>
    </Typography>
  );
};

   useEffect(() => {
        // Scroll automatico verso il basso
        if (avvisiRef.current) {
            avvisiRef.current.scrollTop = avvisiRef.current.scrollHeight;
        }
    }, [avvisi]);

  return (


    <div style={{ display: 'flex', justifyContent: 'center'  }}>

    {showSearchOnBinG ? (
        <SearchOnBinG onFinishSelecting={handleFinishSelecting} />
      ) : (

      <div style={{ maxWidth: 900, width: '100%' }}>

      <Typography variant="h2"><span role="img" aria-label="Robot">🤖</span> ChatGPMik</Typography>

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div style={{ flex: 1 }}>
        <TextField
          label="Numero totale di token utilizzati"
          value={totalTokens}
          InputProps={{
            readOnly: true,
          }}
        />
        <Typography variant="body1" ml={2}>
          Costo totale: <span>{totalCost.toFixed(2)}€</span>
        </Typography>
      </div>
        <div style={{ flex: 1, justifyContent: 'space-between', marginBottom:'20px' }}>
          <Select value={selectedModel} onChange={handleModelChange} fullWidth lable="Modello">
              <MenuItem value="gpt-3.5-turbo">GPT-3.5-turbo</MenuItem>
              <MenuItem value="gpt-3.5-turbo-16k">GPT-3.5-turbo-16k</MenuItem>
              <MenuItem value="gpt-4">GPT-4</MenuItem>
              <MenuItem value="gpt-4-32k">GPT-4-32k</MenuItem>
              <MenuItem value="Bard">Bard (non va)</MenuItem>
          </Select>
      </div>
      <div style={{ flex: 1, marginLeft: '20px', marginBottom:'20px'}}>
        <TextField
        label="Console"
          multiline
          rows={3}
          variant="outlined"
          value={avvisi}
          onChange={changeAvvisi}
          disabled
          fullWidth
          inputRef={avvisiRef} 
        />
      </div>
    </div>

      <Box mb={2}>
        <TextField style={{ marginTop:'20px' }}
        label="Inserisci link, testo, oppure testo e all'interno link..."
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={textlinks}
        onChange={handleTextLinks2Change}
      />
      </Box>

      <Box mb={2}>
        <Button variant="contained" color="primary" onClick={handleElaboraText} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Elabora link (se presenti)"}
        </Button>
      </Box>

      { showTextFields1 && (
      <Box mb={2} mt={2}>
        <FormControl fullWidth>
          <InputLabel>Seleziona un prompt</InputLabel>
          <Select value={prompts.indexOf(selectedPrompt)} onChange={handlePromptChange} label="Seleziona un prompt">
            {prompts.map((prompt, index) => (
              <MenuItem key={index} value={index}>{prompt.label}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginTop:'20px' }}>
          <Select value={role} onChange={handleRoleChange}>
            <MenuItem value="system">System</MenuItem>
            <MenuItem value="user">User</MenuItem>
          </Select>
        </FormControl>
      <TextField
        multiline
        rows={6}
        variant="outlined"
        fullWidth
        value={textFieldValue}
        onChange={handleTextFieldChange}
      />

        <FormControl fullWidth style={{ marginTop:'20px' }}>
          <Select value={role2} onChange={handleRole2Change}>
            <MenuItem value="user" selected>User</MenuItem>
            <MenuItem value="system">System</MenuItem>
          </Select>
        </FormControl>
      <TextField
        multiline
        rows={6}
        variant="outlined"
        fullWidth
        value={textFieldValue2}
        onChange={handleTextField2Change}
      />

        <Button variant="contained" color="primary" onClick={handlePromptSubmit} disabled={isLoading} style={{ marginTop:'20px' }}>
          {isLoading ? <CircularProgress size={24} /> : "Invia"}
        </Button>
      </Box>
      )}
      
      {showTextFields2 && (
        <Box mt={2}>
        <InputLabel>Articolo generato</InputLabel>
          <TextField
            multiline
            rows={6}
            variant="outlined"
            fullWidth
            value={response}
            onChange={changeResponse}
            InputProps={{
              endAdornment: (
                <CopyToClipboard text={response}>
                  <Button color="primary" variant="contained">
                    Copia negli appunti
                  </Button>
                </CopyToClipboard>
              ),
            }}
          />

        <Button variant="contained" color="primary" onClick={handleOtherSubmit} disabled={isLoading} style={{ marginBottom:'20px' }}>
          {isLoading ? <CircularProgress size={24} /> : "Suddividi la risposta nei vari elementi dell'articolo"}
        </Button>
      </Box>
      )}
      

      {showTextFields3 && (
        <Box>
          <div>
          <TextField
            label={"Titolo ("+editedTitle.length+" caratteri)"}
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <TextField
            label={"Titolo SEO ("+editedTitleSeo.length+" caratteri)"}
            value={editedTitleSeo}
            onChange={(e) => setEditedTitleSeo(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <TextField
            label={"Permalink ("+editedPermalink.length+" caratteri)"}
            value={editedPermalink}
            onChange={(e) => setEditedPermalink(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <TextField
            label={"Meta Description ("+editedMetaDescription.length+" caratteri)"}
            value={editedMetaDescription}
            onChange={(e) => setEditedMetaDescription(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <TextField
            label="Keywords"
            value={editedKeywords}
            onChange={(e) => setEditedKeywords(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />

        <TextField
          multiline
          rows={6}
          label={`Contenuto (${editedContent.split(/\s+/).filter((word) => word !== '').length} parole)`}
          variant="outlined"
          fullWidth
          value={editedContent}
          margin="normal"
          onChange={(e) => setEditedContent(e.target.value)}
        />
        </div>

                  <Select value={website} onChange={handleWebsite2Change} className="sideWidth">
                    <MenuItem value="tutto.tv">tutto.tv</MenuItem>
                    <MenuItem value="tuttoandroid.net">tuttoandroid.net</MenuItem>
                    <MenuItem value="tuttotech.net">tuttotech.net</MenuItem>
                  </Select>

                  <Button color="primary" variant="contained" onClick={handleUploadToWebsite}>
                    Carica su Wordpress
                  </Button>
               </Box>          
               )}
      </div>
      )} </div>
  );
}

export default App;
