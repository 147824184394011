const SummarizeContent = async (text) => {
  let data;
  
  const ipaddress = "100.26.32.20";
  //const ipaddress = "localhost";

    try {

      const message1 = { "role": `system`, "content": `Riassumi questo [TESTO]. Concentrati su dettagli, specifiche e informazioni. Evita offerte e prezzi. Non fare eco alla mia richiesta. Rispondi in ITALIANO.` };
      const message2 = { "role": `user`, "content": `[TESTO]:${text}\n\nFine del testo.` };

      //const response = await fetch('http://54.175.3.49:2001/api/gpt4', {
      const response = await fetch(`http://${ipaddress}:7070/api/gpt3`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message: [message1, message2] })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      data = await response.json();
      console.log(data);

    } catch (error) {
      console.error(error);
    }

  return data;
};

export default SummarizeContent;